import React from 'react';

import {
    ContentLibrarySideWrapper
} from "../styled/pages/about/content-library/ContentLibraryComponents";
import {
    PressLogoCardContainer,
    PressLogoCardInline,
    PressLogoCardLink,
    PressLogoCardTitle,
    PressLogoImg
} from "../styled/cards/PressLogoCardComponents";

const PressLogoCard = ({lang, logo, light, dark}) => {
    return (
        <ContentLibrarySideWrapper>
            <PressLogoCardInline>
                {logo &&
                <PressLogoImg src={logo.url} alt="product-logo"/>
                }
                <PressLogoCardContainer>
                    <PressLogoCardTitle color="body-text" bold upper small>
                        {lang === "hu" ? "SÖTÉT VERZIÓ" : "DARK COLOR VERSION"}
                    </PressLogoCardTitle>
                    {dark && dark.map((item, index) => (
                        <PressLogoCardLink href={item.logo.url} target="_blank" rel="noreferrer" key={index}>
                            {item.extension}
                        </PressLogoCardLink>
                    ))}
                </PressLogoCardContainer>
                <PressLogoCardContainer>
                    <PressLogoCardTitle color="body-text" bold upper small>
                        {lang === "hu" ? "VILÁGOS VERZIÓ" : "LIGHT WHITE VERSION"}
                    </PressLogoCardTitle>
                    {light && light.map((item, index) => (
                        <PressLogoCardLink href={item.logo.url} target="_blank" rel="noreferrer" key={index}>
                            {item.extension}
                        </PressLogoCardLink>
                    ))}
                </PressLogoCardContainer>
            </PressLogoCardInline>
        </ContentLibrarySideWrapper>
    );
};

export default PressLogoCard;