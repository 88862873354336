import React from 'react';

import {Body} from "../styled/typography/Typography";
import {PressContactCardWrapper} from "../styled/cards/PressContactCardComponents";

const PressContactCard = ({name, position, email, phone}) => {
    return (
        <PressContactCardWrapper>
            <Body bold color="body-text" marginBottom="small">
                {name}
            </Body>
            <Body color="body-text" marginBottom="smaller">
                {position}
            </Body>
            <Body color="body-text" marginBottom="smaller">
                <a href={"mailto:" + email}>{email}</a>
            </Body>
            <Body color="body-text">
                {phone}
            </Body>
        </PressContactCardWrapper>
    );
};

export default PressContactCard;