import React, {useState} from 'react';
import {graphql} from "gatsby";
import moment from "moment";
import 'moment/locale/hu';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import Layout from "../../components/layouts/Layout";
import SectionLayoutGrid from "../../components/layouts/SectionLayoutGrid";
import PressLogoCard from "../../components/cards/PressLogoCard";
import PressContactCard from "../../components/cards/PressContactCard";
import PressPostCard from "../../components/cards/PressPostCard";
import PressPostPinnedCard from "../../components/cards/PressPostPinnedCard";
import {
    convertPressCategories,
    convertPressThemes,
    filterPressItems,
    sortPressDate, sortPressPinned
} from "../../components/general/press/PressFunctions";
import {paginateArray} from "../../components/general/content-library/ContentLibraryFunctions";

import {Body, Header2, Title} from "../../components/styled/typography/Typography";
import Col from "../../components/styled/grid/Col";
import {Button, ButtonContainer} from "../../components/styled/button/ButtonComponents";
import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";
import {OnlyDesktop, OnlyMobile} from "../../components/styled/views/MobileOrDesktop";
import {SelectForm} from "../../components/styled/forms/FormComponents";
import {InlineGrid} from "../../components/styled/grid/Grids";

import circuit from "../../../static/assets/animations/circuit/circuit01.json";
import circuit_right from "../../../static/assets/animations/circuit/circuit_right01.json";

const Press = ({data}) => {

    const {press} = data;
    const {allpressposts: {nodes}} = data;
    const {f} = data.allStrapiComponentsData;
    const categories = f[0].filters.find(e => e.apiId === "filter").schema.attributes.category.enum;
    const themes = f[0].filters.find(e => e.apiId === "filter").schema.attributes.theme.enum.filter(f => press.locale === "hu" ? f !== "proxedo_network_security" : (f !== "zorp_gateway" && f !== "zorp_malware_detection"));
    //const country = [...new Set(nodes.map((item) => (item.filtering.country)))]
    const years = [...new Set(nodes.map((item) => (moment(item.date)).format("YYYY")))].sort(sortPressDate);

    const [paginateSize, setPaginateSize] = useState(4);
    const [filtering, setFiltering] = useState(
        {
            theme: "",
            category: "",
            country: "",
            year: "",
        })

    const localizedNodes = nodes.filter(f => press.locale === "hu" ? f.isHungarian : f.isEnglish).sort(sortPressPinned);
    const filteredNodes = paginateArray(filterPressItems(filtering, localizedNodes), paginateSize);

    return (
        <Layout lang={press.locale} seo={press.SEO}>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid customBackground="middle" start="true" id="news">
                <Col>
                    <Title color="white" textAlign="center" marginBottom="normal">
                        {press.news_title}
                    </Title>
                    <InlineGrid>
                        <SelectForm variant="outlined" size="small" autoheight="true" span={4}>
                            <InputLabel id="theme-label">
                                {press.locale === "hu" ? "Téma" : "Theme"}
                            </InputLabel>
                            <Select
                                native
                                name="theme"
                                id="theme"
                                value={filtering.theme}
                                onChange={(e) => setFiltering({...filtering, theme: e.target.value})}
                                label={press.locale === "hu" ? "Téma" : "Theme"}
                            >
                                <option value="" aria-label="None"/>
                                {themes.map(item => (
                                    <option value={item}
                                            key={item}>{convertPressThemes(press.locale, item)}</option>
                                ))}
                            </Select>
                        </SelectForm>
                        <SelectForm variant="outlined" size="small" autoheight="true" span={4}>
                            <InputLabel id="category-label">
                                {press.locale === "hu" ? "Kategória" : "Category"}
                            </InputLabel>
                            <Select
                                native
                                name="category"
                                id="category"
                                value={filtering.category}
                                onChange={(e) => setFiltering({...filtering, category: e.target.value})}
                                label={press.locale === "hu" ? "Kategória" : "Category"}
                            >
                                <option value="" aria-label="None"/>
                                {categories.map(item => ((item === "palyazat" && press.locale === "en") ?
                                        null
                                        :
                                        <option value={item} key={item}>
                                            {convertPressCategories(press.locale, item)}
                                        </option>
                                ))}
                            </Select>
                        </SelectForm>
                        {/*<SelectForm variant="outlined" size="small" autoheight="true" span={3}>
                            <InputLabel id="country-label">
                                {press.locale === "hu" ? "Ország" : "Country"}
                            </InputLabel>
                            <Select
                                native
                                name="country"
                                id="country"
                                value={filtering.country}
                                onChange={(e) => setFiltering({...filtering, country: e.target.value})}
                                label={press.locale === "hu" ? "Ország" : "Country"}
                            >
                                <option value="" aria-label="None"/>
                                {country.map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </Select>
                        </SelectForm>*/}
                        <SelectForm variant="outlined" size="small" autoheight="true" span={4}>
                            <InputLabel id="year-label">
                                {press.locale === "hu" ? "Év" : "Year"}
                            </InputLabel>
                            <Select
                                native
                                name="year"
                                id="year"
                                value={filtering.year}
                                onChange={(e) => setFiltering({...filtering, year: e.target.value})}
                                label={press.locale === "hu" ? "Év" : "Year"}
                            >
                                <option value="" aria-label="None"/>
                                {years.map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </Select>
                        </SelectForm>
                    </InlineGrid>
                </Col>
                {filteredNodes.map((item, index) => (
                    (item.isPinned && filtering.theme === "" && filtering.category === "" && filtering.country === "" && filtering.year === "") ?
                        <Col key={index} grid>
                            <OnlyDesktop>
                                <PressPostPinnedCard
                                    title={item.title}
                                    avatar={item.avatar.url}
                                    slug={item.slug}
                                    date={moment(item.date).locale(press.locale).format("LL")}
                                    short_description={item.short_description}
                                />
                            </OnlyDesktop>
                            <OnlyMobile>
                                <PressPostCard
                                    title={item.title}
                                    avatar={item.avatar.url}
                                    slug={"/press/" + item.slug}
                                    date={moment(item.date).locale(press.locale).format("LL")}
                                    short_description={item.short_description}
                                />
                            </OnlyMobile>
                        </Col>
                        :
                        <Col span={4} grid key={index}>
                            <PressPostCard
                                title={item.title}
                                avatar={item.avatar.url}
                                slug={"/press/" + item.slug}
                                date={moment(item.date).locale(press.locale).format("LL")}
                                short_description={item.short_description}
                                locale={press.locale}
                            />
                        </Col>
                ))}
                {filteredNodes.length === 0 &&
                <Col grid center>
                    <Body color="body-text" bold textAlign="center">
                        {press.locale === "hu" ? "Nincs találat." : "No items found."}
                    </Body>
                </Col>
                }
                <Col grid center>
                    <ButtonContainer>
                        <Button
                            onClick={() => setPaginateSize(paginateSize + 3)}
                            disabled={filteredNodes.length === filterPressItems(filtering, localizedNodes).length}
                        >
                            {press.locale === "hu" ? "Több betöltése..." : "Show more..."}
                        </Button>
                    </ButtonContainer>
                </Col>
            </SectionLayoutGrid>
            <LottieAnimation animationData={circuit_right} right="true"/>
            <SectionLayoutGrid customBackground="middle" customMinHeight="medium" id="logos" customPadding="smallerTop">
                <Col>
                    <Header2 color="white" textAlign="center" marginBottom="small">
                        {press.logo_title}
                    </Header2>
                </Col>
                {press.ProductLogoCard.map((item, index) => (
                    <Col span={4} key={index} smallGap>
                        <PressLogoCard
                            lang={press.locale}
                            light={item.light_logo && item.light_logo}
                            logo={item.product_logo && item.product_logo}
                            dark={item.dark_logo && item.dark_logo}
                        />
                    </Col>
                ))}
            </SectionLayoutGrid>
            <SectionLayoutGrid customBackground="middle" customMinHeight="small" id="contact">
                <Col>
                    <Header2 color="white" textAlign="center" marginBottom="small">
                        {press.contact_title}
                    </Header2>
                </Col>
                <Col span={4}/>
                <Col span={4}>
                    <PressContactCard
                        name={press.contact_card.name}
                        position={press.contact_card.position}
                        email={press.contact_card.email}
                        phone={press.contact_card.phone}
                    />
                </Col>
                <Col span={4}/>
            </SectionLayoutGrid>
        </Layout>
    );
};

export const query = graphql`
   query GetSinglePress($locale: String) {
         press: strapiPress(locale: { eq: $locale }) {
          locale
          news_title
          social_title
          logo_title
          contact_title
          contact_card {
            position
            phone
            name
            id
            email
          }
          SEO {
              title
              isIndexable
              description
              keywords
              preview {
                url
              }
          }
          ProductLogoCard {
            id
            product_logo {
              url
            }
            dark_logo {
              id
              extension
              logo {
                url
              }
            }
            light_logo {
              extension
              id
              logo {
                url
              }
            }
          }
      }
      allpressposts: allStrapiPressPosts {
        nodes {
          avatar {
            url
          }
          isHungarian
          isEnglish
          isPinned
          short_description
          slug
          title
          date
          strapiId
          filtering {
            category
            country
            theme
          }
        }
      }
      allStrapiComponentsData {
            f: nodes {
              filters: data {
                apiId
                schema {
                  attributes {
                    category {
                      enum
                    }
                    theme {
                      enum
                    }
                  }
                }
              }
            }
          }
   }
 `

export default Press;