export const convertCategories = (locale, category) => {
    if (category === "case_study") {
        return locale === "hu" ? "Esettanulmány" : "Case study";
    } else if (category === "white_paper") {
        return locale === "hu" ? "White paper" : "White paper";
    } else if (category === "e_book") {
        return locale === "hu" ? "E-könyv" : "E-book";
    } else if (category === "presentation") {
        return locale === "hu" ? "Prezentáció" : "Presentation";
    } else if (category === "product_brochure") {
        return locale === "hu" ? "Brosúra" : "Product brochure";
    } else if (category === "use_case") {
        return locale === "hu" ? "Alkalmazási terület (use case)" : "Use case";
    } else if (category === "video") {
        return locale === "hu" ? "Videó" : "Video";
    } else if (category === "webinar") {
        return locale === "hu" ? "Webinárium" : "Webinar";
    } else if (category === "corporate_intro") {
        return locale === "hu" ? "Cégbemutató" : "Corporate intro";
    } else if (category === "press_release") {
        return locale === "hu" ? "Sajtóközlemény" : "Press release";
    } else if (category === "infographics") {
        return locale === "hu" ? "Infografika" : "Infographics";
    } else if (category === "blog") {
        return locale === "hu" ? "Blog" : "Blog";
    } else if (category === "news") {
        return locale === "hu" ? "Hír" : "In the news";
    }
}

export const convertThemes = (locale, theme) => {
    if (theme === "network_security") {
        return locale === "hu" ? "Hálózatbiztonság" : "Network Security";
    } else if (theme === "api_security") {
        return locale === "hu" ? "API-biztonság" : "API Security & Management";
    } else if (theme === "identity_governance") {
        return locale === "hu" ? "Jogosultságkezelés" : "Identity Governance";
    } else if (theme === "ad_security") {
        return locale === "hu" ? "AD-biztonság és -kezelés" : "AD Security and Management";
    } else if (theme === "privileged_access_management") {
        return locale === "hu" ? "Kiemeltfelhasználó-kezelés" : "Privileged Access Management";
    } else if (theme === "log_management") {
        return locale === "hu" ? "Naplókezelés" : "Log Management";
    } else if (theme === "it_management") {
        return locale === "hu" ? "IT menedzsment" : "IT Management";
    } else if (theme === "zero_trust") {
        return locale === "hu" ? "Zero Trust" : "Zero Trust";
    } else if (theme === "professional_services") {
        return locale === "hu" ? "Szolgáltatások" : "Professional Services";
    } else if (theme === "ics_security") {
        return locale === "hu" ? "Ipari biztonság" : "ICS Security";
    } else if (theme === "company") {
        return locale === "hu" ? "Vállalati" : "Company";
    }
}

export function sortDate(a, b) {
    return new Date(b.date) - new Date(a.date);
}

export function sortPiece(a, b) {
    return b.downloads.length - a.downloads.length;
}

export function sortCategory(a, b) {
    return (b.count) - (a.count);
}

export function filterItems(filter, array) {
    return array.filter(f => (
        (filter.category !== "" ? f.filters.category === filter.category : f) &&
        (filter.lang !== "" ? f.filters.lang === filter.lang : f) &&
        (filter.theme !== "" ? f.filters.theme === filter.theme : f) &&
        (filter.search !== "" ? f.title.toLowerCase().includes(filter.search.toLowerCase()) : f) &&
        (filter.product !== "" ? f.products.some(e => e.slug === filter.product) : f))
    ).sort(sortDate);
}

export function paginateArray(array, n) {
    return array.slice(0, n);
}