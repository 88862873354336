import styled from "styled-components";
import {Body} from "../typography/Typography";

export const PressLogoImg = styled.img`
  width: 100%;
  max-height: 50px;
  height: auto;
  padding: 10px;
  align-self: center;
  justify-self: center;
`

export const PressLogoCardInline = styled.div`
  background-color: rgba(13, 65, 121, 0.7);
  padding: 0 40px 0 40px;
  display: grid;
  align-content: start;
  height: 100%;
  grid-template-rows: 100px auto;
`

export const PressLogoCardContainer = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-auto-flow: row;
`

export const PressLogoCardLink = styled.a`
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: var(--p-small);
  line-height: var(--p-small-lh);
  color: var(--body-text);
  
  &:hover {
    opacity: 0.6;
  }
`

export const PressLogoCardTitle = styled(Body)`
  opacity: 0.3;
  margin-bottom: 5px;
`