import styled from "styled-components";

export const OnlyMobile = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    display: grid;
  }
`

export const OnlyDesktop = styled.div`
  display: grid;
  @media screen and (max-width: 800px) {
    display: none;
  }
`