import moment from "moment";
import {sortDate} from "../content-library/ContentLibraryFunctions";

export const convertPressCategories = (locale, category) => {
    if (category === "press_release") {
        return locale === "hu" ? "Sajtóközlemény" : "Press release";
    } else if (category === "news") {
        return locale === "hu" ? "Hír" : "News";
    } else if (category === "event") {
        return locale === "hu" ? "Esemény" : "Event";
    } else if (category === "palyazat") {
        return locale === "hu" ? "Pályázat" : "";
    }
}

export const convertPressThemes = (locale, theme) => {
    if (theme === "proxedo_network_security") {
        return locale === "hu" ? "Proxedo Network Security" : "Proxedo Network Security";
    } else if (theme === "proxedo_api_security") {
        return locale === "hu" ? "Proxedo API Security" : "Proxedo API Security";
    } else if (theme === "zorp_gateway") {
        return locale === "hu" ? "Zorp Gateway" : "Zorp Gateway";
    } else if (theme === "zorp_malware_detection") {
        return locale === "hu" ? "Zorp Malware Detection" : "Zorp Malware Detection";
    } else if (theme === "company") {
        return locale === "hu" ? "Vállalati" : "Company";
    } else if (theme === "other") {
        return locale === "hu" ? "Egyéb" : "Other";
    } else if (theme === "zero_trust") {
        return locale === "hu" ? "Zero Trust" : "Zero Trust";
    }
}

export function sortPressDate(a, b) {
    return b - a;
}

export function sortPressPinned(a, b) {
    return b.isPinned - a.isPinned;
};

export function filterPressItems(filter, array) {
    return array.filter(f => (
        (filter.category !== "" ? f.filtering.category === filter.category : f) &&
        (filter.country !== "" ? f.filtering.lang === filter.lang : f) &&
        (filter.theme !== "" ? f.filtering.theme === filter.theme : f) &&
        (filter.year !== "" ? moment(f.date).format("YYYY") === filter.year : f))
    ).sort(sortDate);
}